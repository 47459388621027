import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getGallery } from "../Service/Api";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const Gallery = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getGallery();
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data[0]);
  }, [data]);

  const emptyArray = [
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments:
        "https://webapi.entab.info/api/image/GHSS/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments:
        "https://webapi.entab.info/api/image/GHSS/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
    {
      title: "Gallery Title",
      day: "DD",
      month: "MM",
      attachments:
        "https://webapi.entab.info/api/image/GHSS/public/Images/gallery-pic.jpg",
      description: "Stay tuned for latest updates",
    },
  ];

  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {data.length > 0
            ? data
                .filter((item) => item.category === "PhotoGallery")

                .map((item, index) => (
                  <div className="item" key={index}>
                    <div className="galleryimg">
                      <div className="galleryimgbox">
                        <LazyLoad
                          offset={10}
                          placeholder={
                            <img
                              src="https://webapi.entab.info/api/image/GHSS/public/Images/sld3.jpg"
                              alt="Loading..."
                              className="img-fluid"
                            />
                          }>
                          <img
                            src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                            className="img-fluid"
                            alt="Gandhi High School, Sidhi - Madhya Pradesh"
                          />
                        </LazyLoad>
                        <div className="gallerydesc">
                          <Link to={`SubGallery?id=${item._id}`} className="">
                            <i className="bi bi-zoom-in"></i>
                          </Link>
                          <h6>{item.title}</h6>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            : emptyArray.map((data, index) => (
                <div className="item">
                  <div className="galleryimg">
                    <div className="galleryimgbox">
                      <img
                        src="https://webapi.entab.info/api/image/GHSS/public/Images/gal2.jpg"
                        className="img-fluid"
                        alt="Gandhi High School, Sidhi - Madhya Pradesh"
                      />
                      <div className="gallerydesc">
                        <Link to="#" className="">
                          <i className="bi bi-zoom-in"></i>
                        </Link>
                        <h6>Investiture Ceremony</h6>
                        <p>Investiture Ceremony</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          <div className="item">
            <div className="galleryimg">
              <div className="galleryimgbox">
                <img
                  src="https://webapi.entab.info/api/image/GHSS/public/Images/gal3.jpg"
                  className="img-fluid"
                  alt="Gandhi High School, Sidhi - Madhya Pradesh"
                />
                <div className="gallerydesc">
                  <Link to="#" className="">
                    <i className="bi bi-zoom-in"></i>
                  </Link>
                  <h6>Golden Jubilee celebrations</h6>
                  <p>Golden jubilee celebrations of Gandhi High School</p>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="galleryimg">
              <div className="galleryimgbox">
                <img
                  src="https://webapi.entab.info/api/image/GHSS/public/Images/gal4.jpg"
                  className="img-fluid"
                  alt="Gandhi High School, Sidhi - Madhya Pradesh"
                />
                <div className="gallerydesc">
                  <Link to="#" className="">
                    <i className="bi bi-zoom-in"></i>
                  </Link>
                  <h6>YOGA DAY</h6>
                  <p>YOGA DAY</p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Gallery;
