import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

const HomeEvent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const datas = await getEvents();
      setData(datas);
    };
    getData();
  }, []);
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="row">
        <div className="eventbg">
          <Slider {...settings}>
            {data.length > 0 ? (
              data.map((item, index) => (
                <div className="item">
                  <div className="homenewsbox">
                    <div className="homenewsboximg">
                      <LazyLoad
                        offset={10}
                        placeholder={
                          <img
                            src="https://webapi.entab.info/api/image/GHSS/public/Images/event.jpg"
                            alt="Loading..."
                            className="img-fluid"
                          />
                        }>
                        <img
                          src={
                            item.images.length > 0
                              ? `https://webapi.entab.info/api/image/${item.images}`
                              : "https://webapi.entab.info/api/image/GHSS/public/Images/event.jpg"
                          }
                          className="img-fluid"
                          alt="Gandhi High School, Sidhi - Madhya Pradesh"
                        />
                      </LazyLoad>
                      <div className="homenewsboxtitle">
                        <p> {item.title} </p>
                      </div>
                    </div>
                    <div className="homenewsboxdesc">
                      <div className="homenewsboxbottom">
                        <span>
                          <i className="bi bi-calendar-event"></i> {item.date}
                        </span>
                        <span>
                          <i className="bi bi-clock"></i> {item.time}{" "}
                        </span>
                      </div>
                      <span>
                        <i className="bi bi-geo-alt-fill"></i> {item.venue}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="item">
                <div className="homenewsbox">
                  <div className="homenewsboximg">
                    <img
                      src="https://webapi.entab.info/api/image/GHSS/public/Images/event1.jpg"
                      className="img-fluid"
                      alt="Gandhi High School, Sidhi - Madhya Pradesh"
                    />
                    <div className="homenewsboxtitle">
                      <p> Maharana pratap jayanti </p>
                    </div>
                  </div>
                  <div className="homenewsboxdesc">
                    <div className="homenewsboxbottom">
                      <span>
                        <i className="bi bi-calendar-event"></i> 09 May, 2024
                      </span>
                      <span>
                        <i className="bi bi-clock"></i> 08:00 AM{" "}
                      </span>
                    </div>
                    <span>
                      <i className="bi bi-geo-alt-fill"></i> School Campus
                    </span>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="item">
                         <div className="homenewsbox">
                              <div className="homenewsboximg">
                                 <img src="https://webapi.entab.info/api/image/GHSS/public/Images/event1.jpg" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                                  <div className="homenewsboxtitle"><p> Maharana pratap jayanti </p></div>
                              </div>
                              <div className="homenewsboxdesc">
                                 <div className="homenewsboxbottom">
                                     <span><i className="bi bi-calendar-event"></i> 09 May, 2024</span>
                                     <span><i className="bi bi-clock"></i> 08:00 AM </span>
                                 </div>
                                 <span><i className="bi bi-geo-alt-fill"></i> School Campus</span>
                              </div>
                          </div>
                    </div>
                      
                     <div className="item">
                         <div className="homenewsbox">
                              <div className="homenewsboximg">
                                 <img src="https://webapi.entab.info/api/image/GHSS/public/Images/event2.jpg" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                                  <div className="homenewsboxtitle"><p>International workers day </p></div>
                              </div>
                              <div className="homenewsboxdesc">
                                 <div className="homenewsboxbottom">
                                     <span><i className="bi bi-calendar-event"></i> 12 May, 2024</span>
                                     <span><i className="bi bi-clock"></i> 08:00 AM </span>
                                 </div>
                                 <span><i className="bi bi-geo-alt-fill"></i> School Campus</span>
                              </div>
                          </div>
                    </div>
                     <div className="item">
                         <div className="homenewsbox">
                              <div className="homenewsboximg">
                                 <img src="https://webapi.entab.info/api/image/GHSS/public/Images/event3.jpg" className="img-fluid" alt="Gandhi High School, Sidhi - Madhya Pradesh"/>
                                  <div className="homenewsboxtitle"><p> ravindra nath tagore jayanti </p></div>
                              </div>
                              <div className="homenewsboxdesc">
                                 <div className="homenewsboxbottom">
                                     <span><i className="bi bi-calendar-event"></i> 08 May, 2024</span>
                                     <span><i className="bi bi-clock"></i> 08:00 AM </span>
                                 </div>
                                 <span><i className="bi bi-geo-alt-fill"></i> School Campus</span>
                              </div>
                          </div>
                    </div> */}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default HomeEvent;
