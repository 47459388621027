import React from "react";
import Header from "../Component/Header";
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";
import LazyLoad from "react-lazyload";
const ComingSoon = () => {
  return (
    <>
      <Header />
      <div className="innerslide Admissionbg">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li> Coming Soon </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1> Coming Soon </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 circular_sec">
              <LazyLoad
                offset={10}
                placeholder={
                  <img
                    src="https://webapi.entab.info/api/image/GHSS/public/Images/sld3.jpg"
                    alt="Loading..."
                    className="img-fluid"
                  />
                }>
                <img
                  src="https://webapi.entab.info/api/image/GHSS/public/Images/coming-soon.jpg"
                  alt="Gandhi High School, Sidhi, MP"
                  className="coming_soon img-fluid"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ComingSoon;
