import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { getHeader } from "../config";
// import { form } from "../Service/Api";
import axios from "axios"
import { API_URL, getHeader } from "../config"
export const form = async (formData, header) => {
    try {
      console.log('Sending request to:', `${API_URL}/api/form/${formData.schoolcode}`);
      console.log('Form data:', formData);
      console.log('Header:', header);
  
      const { data } = await axios.post(`${API_URL}/api/form/${formData.schoolcode}`, formData, { headers: header });
      console.log('Response data:', data);
      return data;
    } catch (error) {
      console.error('Error in form submission:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
      }
      throw error;
    }
  };
const Footer = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        schoolcode: "GHSS" // or other school code
      });
      const [errors, setErrors] = useState({});
      const [submitted, setSubmitted] = useState(false);
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) {
          newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = "Email is invalid";
        }
        if (!formData.message) newErrors.message = "Message is required";
        return newErrors;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        try {
          const header = getHeader();
          console.log('Submitting form with data:', formData);
          const response = await form(formData, header);
          console.log('Form submitted successfully:', response);
          setSubmitted(true);
          setFormData({ name: "", email: "", message: "", schoolcode: formData.schoolcode });
          setErrors({});
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };

    return (
        <>
            <div className="footer">
                <div className="container-fluid fulwidth">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="footerlogo">
                                <img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerlogo.png" className="img-fluid" />
                            </div>
                            <div className="footerinfo">
                                <h6>Meet the Principal</h6>
                                <div className="footerinfodiv">
                                    <ul>
                                        <li>Mon</li>
                                        <li>Tue</li>
                                        <li>Thu</li>
                                        <li>Fri</li>
                                    </ul>
                                </div>
                                <div className="footerinfodivbottom">
                                    <p>09:00 am - 11:00 am</p>
                                </div>
                            </div>
                            <div className="footerinfo">
                                <h6>Office hours</h6>
                                <div className="footerinfodiv">
                                    <ul>
                                        <li>Mon - fri</li>
                                    </ul>
                                </div>
                                <div className="footerinfodivbottom">
                                    <p>09:00 am - 01:00 pm</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="quick-link">
                                <h3>Important</h3>
                                <ul>
                                    <li><Link to="/Prayers"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Prayers </Link></li>
                                    <li><Link to="/ComingSoon"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Pledge </Link></li>
                                    <li><Link to="/GemGoodBehaviour"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Gems of Good Behavior </Link></li>
                                    <li><Link to="/RulesDiscipline"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Rules of Discipline </Link></li>
                                    <li><Link to="/ParentTeacherCooperation"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Parent Teacher Cooperation </Link></li>
                                    <li><Link to="/EducationalRightsMinorities"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Rights of the Minorities </Link></li>
                                    <li><Link to="/Holidays"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> List of Holidays </Link></li>
                                    <li><Link to="/SiteMap"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/footerarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /> Sitemap </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-12">
                        <form onSubmit={handleSubmit}>
            <div className="footerform">
            {submitted && <p className="success-message">Thank you for your message!</p>}
              <h6>Keep in touch</h6>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>
              <div className="form-group">
                <input type="submit" value="SUBMIT" />
              </div>
            </div>
          </form>
          
                        </div>
                        <div className="col-xl-3 col-lg-12 col-md-12">
                            <div className="d-flex">
                                <div className="app-cont">
                                    <p>Download Mobile Apps  A Common Platform For Educators, Parents & Teachers</p>
                                    <div>
                                        <Link to="https://apps.apple.com/in/app/campuscare/id1034721587" target='_blank'><img src="https://webapi.entab.info/api/image/GHSS/public/Images/app1.png" /></Link>
                                        <Link to="https://play.google.com/store/apps/details?id=com.campuscare.entab.ui" target='_blank'><img src="https://webapi.entab.info/api/image/GHSS/public/Images/app2.png" /></Link>
                                    </div>
                                    <p className="link">
                                        <Link to="https://ghssidhi.campussoft.net/" target='_blank'>Use URL: ghssidhi.campussoft.net</Link>
                                    </p>
                                </div>
                                <div className="phone-img">
                                    <img src="https://webapi.entab.info/api/image/GHSS/public/Images/app3.png" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>©Copyright 2024 By Gandhi High School | Created By Entab Infotech:   <Link to="#">CampusCare®️ </Link></p>
                </div>
            </div>
        </>
    );
}

export default Footer;
