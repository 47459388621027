import React, { useState , useEffect} from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from "../Service/Api"; 
const Testimonials = () => {

  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const messages = await getPrincipalMessage();
      const filterData = messages.filter((item) => item.category === "Principal") 
      setData(filterData);
    };
    fetchData();
  }, []);

  const testimonials = [
    {
      name: data[0]?.name || '',
      designation: data[0]?.designation || '',
      message: 'Principal’s Message',
      testimonial: data[0]?.message || '',
      image: data[0]?.attachments ? `https://webapi.entab.info/api/image/${data[0].attachments}` : 'https://webapi.entab.info/api/image/GHSS/public/Images/principal.jpg',
      link: '/PrincipalsMessage'
    },
    {
      name: 'Fr. Justin Thundiyil',
      designation: 'MANAGER',
      message: 'Manager’s Message',
      testimonial:  'The school web site of Gandhi High School Sidhi is indeed a small effort to disseminate information on the where about of the institution. It is believed that the web site can make the information available few key strokes away and by means of which it would be easy for anyone to access it and also enjoy his or her privilege of right to information at his personal convenience. Education has a unique impact on individual care, enveloping persons, while being vibrantly collective in its input and purpose. It opens the door of the vast knowledge that is stored in books, both physical and online. He or she can use this information in his life for his/her own benefits or for the well being of the society.  We wish everyone every success',
      image: 'https://webapi.entab.info/api/image/GHSS/public/Images/manager.jpg',
      link: '/ManagersMessage'
    },
    {
      name: 'Rev. Bishop Mar Joseph Kodakallil',
      designation: 'Bishop',
      message: 'Bishop’s Message',
      testimonial: ' School is an educational institution of excellence and it is engaged in forming the future generations through a value based education system. Its service and dedication in the educational sector for more than Fifty two is remarkable. It has crossed many mile stones in providing quality education to its children. The well qualified and committed team of staff is the strength of Gandhi High School Sidhi. The Satna Diocesan Society is proud of its achievements and happy to be at the service of the people of Sidhi. I earnestly wish that the Gandhi high school students and its alumini may brighten our nation. They may be illumined and inspired by the values of truthfulness, humility, respect, tolerance and sincerity to fight against the evils prevalent in the society. ',
      image: 'https://webapi.entab.info/api/image/GHSS/public/Images/bishop.jpg',
      link: '/BishopMessage'  
    }
    // Add more testimonials as needed
  ];

    // const testimonials = [
    //     {
    //         name: ' Sr. Lincey ',
    //         designation:'principal',
    //         message:' Principal’s Message',
    //         testimonial: ' “I have a plan for you, says the Lord, a plan for your great future.” God takes pleasure in watching his children do what he created them to do. Parents and teachers are the potters who mould children according to the divine plan of God. If a plant is carefully nurtured by a gardener, it will become good and produce better fruit. Education is not just a process of giving knowledge for a future job but a lifelong process which creates an understanding of moral and ethical values to guide one’s life and make our students the future hope of our country.',
    //         image: '/Images/principal.jpg'
    //     },
    //     {
    //         name: ' Fr. Justin Thundiyil ',
    //         designation:'MANAGER',
    //         message:' Manager’s Message',
    //         testimonial: ' The school web site of Gandhi High School Sidhi is indeed a small effort to disseminate information on the where about of the institution. It is believed that the web site can make the information available few key strokes away and by means of which it would be easy for anyone to access it and also enjoy his or her privilege of right to information at his personal convenience. Education has a unique impact on individual care, enveloping persons, while being vibrantly collective in its input and purpose. It opens the door of the vast knowledge that is stored in books, both physical and online. He or she can use this information in his life for his/her own benefits or for the well being of the society.  We wish everyone every success',
    //         image:
    //         /Images/manager.jpg
    //     },
    //     {
    //         name: ' Rev. Bishop Mar Joseph Kodakallil',
    //         designation:'Bishop',
    //         message:' Bishops’s Message',
    //         testimonial: ' School is an educational institution of excellence and it is engaged in forming the future generations through a value based education system. Its service and dedication in the educational sector for more than Fifty two is remarkable. It has crossed many mile stones in providing quality education to its children. The well qualified and committed team of staff is the strength of Gandhi High School Sidhi. The Satna Diocesan Society is proud of its achievements and happy to be at the service of the people of Sidhi. I earnestly wish that the Gandhi high school students and its alumini may brighten our nation. They may be illumined and inspired by the values of truthfulness, humility, respect, tolerance and sincerity to fight against the evils prevalent in the society. ',
    //         image: '/Images/bishop.jpg'
    //     }
    //     // Add more testimonials as needed
    // ];

    const handleTestimonialClick = (index) => {
        setSelectedTestimonial(selectedTestimonial === index ? 0 : index);
    };

    return (
        <div className="message-container"> 
             <div className="messagebox">
                 <div className={`${selectedTestimonial !== 0 ? 'slide-in' : 'slide-out'}`}>
                {selectedTestimonial !== null && (
                    <div>
                       <div className="messagedesc">
                          <div className="maintitle"> <h1>{testimonials[selectedTestimonial].message}</h1></div>
                          <p>{testimonials[selectedTestimonial].testimonial}</p>
                       
                       </div>
                        <div className="messageboximg">
                           <p>{testimonials[selectedTestimonial].name} <span>{testimonials[selectedTestimonial].designation}</span> </p>
                        </div>
                        <div className="mainbtn"><Link to={testimonials[selectedTestimonial].link}> <img src="https://webapi.entab.info/api/image/GHSS/public/Images/readmore.png" className="img-fluid"/> </Link></div>
                    </div>
                )}
            </div>
            </div> 
            <div className="messageimg">
            {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial">
                    <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        onClick={() => handleTestimonialClick(index)}
                        className={selectedTestimonial === index ? 'active' : ''}
                    />
                </div>
            ))}
        </div>
        </div>
    );
};

export default Testimonials;
