import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const ManagersMessage = () => {
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>
                <li>  Management  </li>
                <li>  Manager's Message </li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Manager's Message </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="msgbox">
                            <img src="https://webapi.entab.info/api/image/GHSS/public/Images/manager.jpg" alt="Gandhi High School, Sidhi, MP" className="img-fluid"/>
                        </div>
                        <p>The school web site of Gandhi High School Sidhi is indeed a small effort to disseminate information on the where about of the institution. It is believed that the web site can make the information available few key strokes away and by means of which it would be easy for anyone to access it and also enjoy his or her privilege of right to information at his personal convenience. Education has a unique impact on individual care, enveloping persons, while being vibrantly collective in its input and purpose. It opens the door of the vast knowledge that is stored in books, both physical and online. He or she can use this information in his life for his/her own benefits or for the well being of the society. <br/> <strong>We wish everyone every success</strong> </p>
                        <p className="name">Fr. Thomas Thelakkat<span>
                
                Manager</span></p>
                    </div>
            </div> 
            </div>
        </div>
       <Footer/>
     </>
  )
}

export default ManagersMessage
