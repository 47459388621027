import React from 'react' 
import { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import Highlights from'../Component/Highlights'
import { Link } from 'react-router-dom'
const Header = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  return (
        <>
        <div className="topheader">
            <div className="topheaderleft">
                <img src="https://webapi.entab.info/api/image/GHSS/public/Images/phone.gif" className="img-fluid"/>
                <p><Link to="tel: +91 9424746251">+91 9424746251</Link> 
                </p>
                <img src="https://webapi.entab.info/api/image/GHSS/public/Images/mail.gif" className="img-fluid"/>
                <Link to="mailto: gandhihighschoolsidhi@gmail.com">gandhihighschoolsidhi@gmail.com </Link>
            </div>
            <Highlights/>
            <div className="topheaderright">
            <Link to="#"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/twiter.png" className="img-fluid"/></Link>
            <Link to="#"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/youtube.png" className="img-fluid"/></Link>
            <Link to="#"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/fb.png" className="img-fluid"/></Link>
            </div>
      </div>
        <div className="header">
       
        <div className="logo">
            <NavLink to="/"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/logo.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/></NavLink>
        </div>
        <div className="header-nav">
            <nav className="navbar navbar-expand-lg">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                    <li className="nav-item active">  <NavLink className="nav-link"><img src="https://webapi.entab.info/api/image/GHSS/public/Images/homeicn.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/> </NavLink> </li>
                     <li className="nav-item dropdown">
                      <div className="nav-link"  data-bs-toggle="dropdown">  School <img src="https://webapi.entab.info/api/image/GHSS/public/Images/navarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/> </div>
                         <ul className="dropdown-menu fade-up">
                         <li><NavLink  className="dropdown-item" to="/Prayers">Prayers</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/Preamble">Preamble</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/ComingSoon">Pledge</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/VisionMission">Vision and Mission</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/Admission">Admission</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/GemGoodBehaviour">Gems of good behaviour</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/RulesDiscipline">Rules of Discipline</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/ParentTeacherCooperation">Parent teacher Co-operation</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/EducationalRightsMinorities">The Educational Rights Of The Minorities</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/Holidays">List of Holidays</NavLink></li>
                         <li><NavLink  className="dropdown-item" to="/Examination">Unit /Quaterly / Halfyearly Examinations</NavLink></li> 
                        </ul> 
                    </li> 
                    <li className="nav-item dropdown">
                       <div className="nav-link" data-bs-toggle="dropdown">   Management  <img src="https://webapi.entab.info/api/image/GHSS/public/Images/navarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/></div>
                        <ul className="dropdown-menu fade-up">
                            <li><NavLink  className="dropdown-item" to="/BishopMessage">Bishop's Message </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/ManagersMessage">Manager's Message </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/PrincipalsMessage">Principal's Message </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/ManagementStaff">Management &amp; Staff </NavLink></li>
                            <li><NavLink  className="dropdown-item" to="/ComingSoon">Activities </NavLink></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <div className="nav-link"  data-bs-toggle="dropdown">  Office Bearers <img src="https://webapi.entab.info/api/image/GHSS/public/Images/navarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/>  </div>
                         <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/ComingSoon"> Office Bearers </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/SchoolCabinet"> School Cabinet  </NavLink></li> 
                        </ul> 
                    </li>
                    <li className="nav-item dropdown">
                      <div className="nav-link"  data-bs-toggle="dropdown"> Academic <img src="https://webapi.entab.info/api/image/GHSS/public/Images/navarrow.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid"/></div>
                          <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/News">  News </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/ComingSoon"> Syllabus </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/GHSS/public/pdf/timetable.pdf" target="_blank">Timetable </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/Toppers">Board Toppers </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/OtherToppers">Class Toppers </NavLink></li>
                        </ul> 
                    </li>  
                    <li className="nav-item">  <NavLink className="nav-link" to="/Kindergarten">Kindergarten </NavLink> </li>
                     
                    <li className="nav-item">  <NavLink className="nav-link" to="/ContactUs">Contact Us </NavLink> </li>
                </ul>
                </div> 
           </nav>
        </div>
        <div className="header-info">
             <div className="header-info-icn">
                  <NavLink to="/Pphelp"> <img src="https://webapi.entab.info/api/image/GHSS/public/Images/parent.png" className="img-fluid"/></NavLink>  
                  <NavLink to="/ErpSoftware"> <img src="https://webapi.entab.info/api/image/GHSS/public/Images/erp.png" className="img-fluid"/></NavLink>  
             </div>
        </div>
    </div>
    </>
  )
}

export default Header
